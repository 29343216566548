<template>
  <v-container>
    <v-row>
      <v-breadcrumbs :items="bread" large></v-breadcrumbs>
    </v-row>
    <div>
      <h1>Importar Legajos</h1>
    </div>
    <v-row>
      <v-col>
        Subir archivo XLXS
        <div v-if="!archivo || !isValidFile">
          Selecciona un archivo .xlsx para importar
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-file-input
          v-model="archivo"
          label="Archivo"
          accept=".xlsx"
          @change.native="validateFile"
        ></v-file-input>
      </v-col>
      <v-col cols="4">
        <v-btn
          @click="uploadFile"
          color="primary"
          :disabled="archivo.length === 0 || !isValidFile"
          :loading="loadingCarga"
          >Importar</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-simple-table dense fixed-header>
            <thead>
              <tr>
                <th
                  class="text-left"
                  v-for="(h, idx) in table.headers"
                  :key="idx"
                >
                  {{ h }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, idx) in table.data" :key="idx">
                <td>
                  {{ d.A }}
                </td>
                <td>
                  {{ d.B }}
                </td>
                <td>
                  {{ d.C }}
                </td>
                <td>
                  {{ d.D }}
                </td>
                <td>
                  {{ d.E }}
                </td>
                <td>
                  {{ d.F }}
                </td>
                <td>
                  {{ d.G }}
                </td>
                <td>
                  {{ d.H }}
                </td>
                <td>
                  {{ d.I }}
                </td>
                <td>
                  {{ d.J }}
                </td>
                <td>
                  {{ d.K }}
                </td>
                <td>
                  {{ d.L }}
                </td>
                <td>
                  {{ d.M }}
                </td>
                <td>
                  {{ d.N }}
                </td>
                <td>
                  {{ d.O }}
                </td>
                <td>
                  {{ d.P }}
                </td>
                <td>
                  {{ d.Q }}
                </td>
                <td>
                  {{ d.R }}
                </td>
                <td>
                  {{ d.S }}
                </td>
                <td>
                  {{ d.T }}
                </td>
                <td>
                  {{ d.U }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
import { API_URL } from '@/common/config';

export default {
  data: () => ({
    table: {
      headers: [],
      data: [],
    },
    loadingCarga: false,
    archivo: [],
    success: false,
    successMensaje: '',
    isValidFile: false,
    excel: [],
    bread: [
      {
        text: 'Mendoza Fiduciaria',
        disabled: false,
        href: '/',
      },
      {
        text: 'Legajos',
        disabled: true,
        href: '/',
      },
      {
        text: 'Importar Legajos',
        disabled: true,
        href: '/',
      },
    ],
    importErrors: [],
  }),
  name: 'ImportarLegajo',
  methods: {
    validateFile() {
      if (this.archivo) {
        const file = this.archivo;
        const fileExtension = file.name.split('.').pop().toLowerCase();
        this.isValidFile = fileExtension === 'xlsx';
      } else {
        this.isValidFile = false;
      }
    },
    validateTipoPersona(data) {},
    async uploadFile() {
      this.loadingCarga = true;
      const url = `${API_URL}api/legajos/importar`;
      const header = {
        headers: {
          token: localStorage.getItem('token'),
        },
      };
      const data = new FormData();
      data.append('adjunto', this.archivo);
      await axios
        .post(url, data, header)
        .then((r) => {
          if (r.data.status === 'success') {
            this.success = true;
            this.successMensaje = 'Excel leido con exito';
            this.table.headers = r.data.headers;
            this.table.data = r.data.data;
          }
          if (r.data.status === 'Error') {
            let ulHtml = '';

            if (typeof r.data.errors === 'object' && r.data.errors !== null) {
              for (const error of r.data.errors) {
                const email = error.row;
                ulHtml += `<li>${email}</li>`;
              }

              ulHtml = `<ul>${ulHtml}</ul>`;

              this.$swal.fire('Error', `${r.data.message}${ulHtml}`, 'error');
            } else {
              this.$swal.fire('Error', `${r.data.message}`, 'error');
            }
          }
        })
        .catch((error) => {
          console.log(
            'Error al importar',
            error.message,
            'error',
            error.errors
          );
          this.$swal.fire('Alerta', 'Ocurrió un error al importar.', 'error');
          this.loadingCarga = false;
        })
        .finally(() => {
          this.loadingCarga = false;
        });
    },
  },
};
</script>
